import React from 'react';

import type { IconType } from '@ninox/ninox-components/lib/Icon';
import { Icon } from '@ninox/ninox-components/lib/Icon';

import { Container, Content, IconWrapper, Title } from './InfoCard.styles';

type Properties = {
    content: string;
    icon: IconType;
    title: string;
};

export const InfoCard = ({ content, icon, title }: Properties) => {
    return (
        <Container>
            <IconWrapper>
                <Icon icon={icon} />
            </IconWrapper>
            <Title>{title}</Title>
            <Content>{content}</Content>
        </Container>
    );
};
