import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { NavLink, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';

import { useIds } from '@ninox/ninox-components/hooks/useId';
import { useMediaQuery } from '@ninox/ninox-components/hooks/useMediaQuery';
import { useMoveFocus } from '@ninox/ninox-components/hooks/useMoveFocus';
import { MediaQuery } from '@ninox/ninox-components/theme';
import { LocaleContext } from '@ninox/ninox-locales/react';

import { LinkButton } from '../../components/LinkButton';
import { SessionContext } from '../../session/session.context';
import type { LoaderData } from '../../session/session.types';
import { track } from '../../tracking/MixPanel';
import type { NavRoute } from '../Nav';
import { Backdrop, Bottom, Opener, Separator, Sidebar, TabButton, TabPanel, Top } from './SideNav.styles';

const ids = ['selected', 'panel', 'slide'] as const;
const APP_PATH = process.env.APP_PATH || '';

type Properties = {
    children?: React.ReactNode;
    mediaQuery?: string;
    isOpened: boolean;
    isPublic: boolean;
    setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SideNav({ children, mediaQuery = MediaQuery.S, isOpened, isPublic, setIsOpened }: Properties) {
    const isMatching = useMediaQuery(mediaQuery);
    const ref = useRef<HTMLDivElement>(null);
    const locale = useContext(LocaleContext);
    const { license, session: sessionInfo } = useRouteLoaderData('root') as LoaderData;
    const { features } = license?.license || {};
    const navigate = useNavigate();
    const location = useLocation();
    const { selected, panel, slide } = useIds(ids);
    const { user } = useContext(SessionContext);

    const toggle = useCallback(() => {
        setIsOpened((prev) => !prev);
    }, [setIsOpened]);

    const open = useCallback(() => {
        setIsOpened(true);
    }, [setIsOpened]);

    const close = useCallback(() => {
        setIsOpened(false);
    }, [setIsOpened]);

    const publicCloudNav = useMemo(
        () =>
            [
                { value: 'profile', trackKey: 'Profile', label: locale.profile },
                { value: 'security', trackKey: 'SecurityAndPrivacy', label: locale.securityPrivacy },
                { value: 'integrations', trackKey: 'Integrations', label: locale.integrations },
                { value: 'subscriptions', trackKey: 'Subscriptions', label: locale.subscriptions },
                user?.verified &&
                    ((user.isBusiness && user.enableCompanyAccountSelfService) || user.companyId) && {
                        value: 'manage-clouds',
                        trackKey: 'ManageClouds',
                        label: locale.managePrivateClouds
                    }
            ].filter(Boolean) as NavRoute[],
        [locale, user]
    );
    const privateCloudNav = useMemo(
        () =>
            sessionInfo.isRoot
                ? ([
                      { value: 'workspaces', trackKey: 'Teams', label: locale.workspacesTeams },
                      { value: 'users', trackKey: 'Users', label: locale.collaborators },
                      { value: 'security', trackKey: 'Security', label: locale.securityPrivacy },
                      { value: 'integrations', trackKey: 'Integrations', label: locale.integrations },
                      { value: 'subscriptions', trackKey: 'Subscriptions', label: locale.subscriptions },
                      { value: 'configuration', trackKey: 'Configuration', label: locale.configuration },
                      features?.customEmailTemplates && {
                          value: 'customization',
                          trackKey: 'Customization',
                          label: locale.customization
                      },
                      { value: 'maintenance', trackKey: 'Maintenance', label: locale.maintenance },
                      features?.processMonitor && {
                          value: 'process-monitor',
                          trackKey: 'ProcessMonitor',
                          label: locale.processMonitor
                      }
                  ].filter(Boolean) as NavRoute[])
                : ([
                      { value: 'security', trackKey: 'Security', label: locale.securityPrivacy },
                      { value: 'subscriptions', trackKey: 'Subscriptions', label: locale.subscriptions }
                  ] as NavRoute[]),
        [locale, features, sessionInfo]
    );

    const routes = isPublic ? publicCloudNav : privateCloudNav;
    const movefocus = useMoveFocus(ref, selected.id, '[role="tab"]');
    const [currentRoute, setCurrentRoute] = useState<NavRoute | undefined>();

    useEffect(() => {
        const firstSegment = location.pathname.split('/').filter(Boolean)[0];
        const matchingRoute = routes.find((route) => route.value === firstSegment);

        setCurrentRoute(matchingRoute);
    }, [location.pathname, routes]);

    useLayoutEffect(() => {
        setIsOpened(!isMatching);
    }, [setIsOpened, isMatching]);

    const keydown = useCallback(
        (e: React.KeyboardEvent<HTMLElement>) => {
            switch (e.code) {
                case 'ArrowDown':
                    e.preventDefault();
                    movefocus(true);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    movefocus(false);
                    break;
                case 'Home':
                    e.preventDefault();
                    movefocus('first');
                    break;
                case 'End':
                    e.preventDefault();
                    movefocus('last');
                    break;
                case 'Spacebar':
                case 'Enter':
                    toggle();
                    break;
                case 'Escape':
                    close();
                    break;
            }
        },
        [movefocus]
    );

    const toggleOnFocus = useCallback<React.FocusEventHandler>((e) => {
        const target = e.target as HTMLElement;

        if (target.hasAttribute('aria-current')) {
            open();
        } else if (target.getAttribute('role') === 'tab') {
            close();
        }
    }, []);

    useEffect(() => {
        // focus current route when opening
        if (!ref.current || !isOpened) return;

        const link = ref.current.querySelector<HTMLAnchorElement>(`[aria-current]`);

        if (!link) return;

        link.focus();
    }, [isOpened, location.pathname]);

    useEffect(() => {
        // navigate after focus change but prevent changing nested route to parent on refresh or url entry
        const routeSegments = currentRoute?.value.split('/').filter(Boolean);
        const pathnameSegments = location.pathname.split('/').filter(Boolean);

        if (routeSegments && routeSegments[0] === pathnameSegments[0]) return;

        if (currentRoute?.trackKey) {
            track(currentRoute?.trackKey, 'Open');
        }

        if (currentRoute?.value) {
            navigate(currentRoute?.value, { unstable_viewTransition: true });
        }
    }, [currentRoute]);

    return (
        <>
            {!isMatching && <Backdrop aria-hidden={!isOpened} role="button" onClick={close} />}
            <Opener role="button" tabIndex={-1} onPointerDownCapture={open} />
            <Sidebar
                ref={ref}
                aria-expanded={isMatching || isOpened}
                aria-orientation="vertical"
                as="nav"
                autoHide={false}
                isRelative={isMatching}
                role="tablist"
                showBackground={false}
                style={{ viewTransitionName: slide.id }}
                tabIndex={-1}
                onFocus={isMatching ? undefined : toggleOnFocus}
                onKeyDown={keydown}
            >
                <Top>
                    {routes.map((route) => {
                        const isCurrent = currentRoute?.value === route.value;

                        return (
                            <TabButton
                                key={route.value}
                                aria-controls={panel.id}
                                aria-disabled={route.disabled}
                                aria-hidden={!isCurrent}
                                aria-selected={isCurrent}
                                as={NavLink}
                                autoFocus
                                data-path={route.value}
                                id={isCurrent ? selected.id : undefined}
                                kind="button"
                                role="tab"
                                tabIndex={isCurrent ? 0 : -1}
                                to={route.value}
                                onClick={preventDefault}
                                onFocus={() => {
                                    setCurrentRoute(route);
                                }}
                            >
                                {route.label}
                            </TabButton>
                        );
                    })}
                </Top>
                <Separator />
                <Bottom>
                    <LinkButton
                        emphasis="quaternary"
                        icon="login"
                        role="button"
                        style={{ width: '100%', justifyContent: 'center' }}
                        tabIndex={1}
                        to={APP_PATH}
                    >
                        {locale.backToApp}
                    </LinkButton>
                </Bottom>
            </Sidebar>
            <TabPanel
                aria-labelledby={selected.id}
                id={panel.id}
                role="tabpanel"
                tabIndex={-1}
                onFocus={isMatching ? undefined : close}
            >
                {children}
            </TabPanel>
        </>
    );
}

function preventDefault(e: React.UIEvent | React.FocusEvent) {
    e.preventDefault();
}
