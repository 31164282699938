import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { Outlet, useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { Button } from '@ninox/ninox-components/lib/Button';
import { NotificationContext } from '@ninox/ninox-components/lib/Notifications';
import { LocaleFormatContext } from '@ninox/ninox-components/locale';
import { LocaleContext } from '@ninox/ninox-locales/react';

import { ProgressNav } from '../../../../shared/ProgressNav';
import { OrderContext } from '../OrderCloud/OrderCloud.context';
import type { LoaderData } from './CreateCloud.loader';

export const Layout = () => {
    const { add } = useContext(NotificationContext);
    const locale = useContext(LocaleContext);
    const { actions } = useContext(OrderContext);
    const { country } = useContext(LocaleFormatContext);
    const navigate = useNavigate();
    const { companyInfo } = useLoaderData() as LoaderData;
    const params = useParams();
    const isConfirmationPage = !!params.domain;

    useEffect(() => {
        actions.updateOrder({
            city: companyInfo?.address.city || '',
            companyId: companyInfo?.id || '',
            companyName: companyInfo?.name || '',
            firstName: companyInfo?.billingFirstName || '',
            lastName: companyInfo?.billingLastName || '',
            invoicingEmail: companyInfo?.billingEmail || '',
            country: country(companyInfo?.address.country) || '',
            countryCode: companyInfo?.address.country || '',
            region: companyInfo?.address.region || '',
            street: companyInfo?.address.street || '',
            zip: companyInfo?.address.postCode || ''
        });
    }, [JSON.stringify(companyInfo)]);

    const onBack = useCallback(() => {
        const confirm = () => {
            navigate('..', { unstable_viewTransition: true });
        };

        if (isConfirmationPage) {
            confirm();

            return;
        }

        add({
            type: 'modal',
            title: locale.order,
            message: locale.confirmLeaveOrder,
            buttons: [
                {
                    emphasis: 'secondary',
                    formNoValidate: true,
                    style: { flex: '1 1 auto' },
                    type: 'submit',
                    value: 'cancel',
                    children: locale.cancel
                },
                {
                    emphasis: 'primary',
                    attention: true,
                    style: { flex: '1 1 auto' },
                    type: 'submit',
                    value: 'accept',
                    onClick: confirm,
                    children: locale.okay
                }
            ]
        });
    }, [navigate, isConfirmationPage]);

    const routes = useMemo(() => {
        if (isConfirmationPage) {
            return [
                { value: 'domain', label: locale.domainInfo },
                { value: 'confirmation', label: locale.billingInfo },
                { value: 'summary', label: locale.orderSummary }
            ];
        }

        return [
            { value: 'domain', label: locale.domainInfo },
            { value: 'billing', label: locale.billingInfo },
            { value: 'summary', label: locale.orderSummary }
        ];
    }, [locale, isConfirmationPage]);

    return (
        <>
            <Button emphasis="tertiary" icon="chevron_left" narrow onClick={onBack}>
                {locale.back}
            </Button>

            {isConfirmationPage ? (
                <Outlet />
            ) : (
                <ProgressNav backwardsOnly routes={routes} style={{ marginLeft: 0, maxWidth: '50rem' }}>
                    <Outlet />
                </ProgressNav>
            )}
        </>
    );
};
