import { styled } from 'styled-components';

import { BorderRadius, BorderWidth, FontSize, FontWeight, Size, Spacing } from '@ninox/ninox-components/theme';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 15rem;
    height: fit-content;

    padding: ${Spacing.large};

    color: ${({ theme }) => theme.text.primary};

    border-color: ${({ theme }) => theme.grid.primary};
    border-radius: ${BorderRadius.largest};
    border-width: ${BorderWidth.tiny};
    border-style: solid;

    & > * {
        margin-bottom: ${Spacing.small};
    }
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.text.primary};

    font-size: ${FontSize.x_large};
    font-weight: ${FontWeight.semibold};
    text-wrap: pretty;
`;

export const Content = styled.div`
    color: ${({ theme }) => theme.text.secondary};

    font-size: ${FontSize.small};
    font-weight: ${FontWeight.medium};
    text-wrap: ballance;
`;

export const IconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    height: ${Size.xl};
    width: ${Size.xl};

    border-radius: 50%;
    border-color: ${({ theme }) => theme.grid.accent};
    border-width: ${BorderWidth.small};
    border-style: solid;

    background-color: ${({ theme }) => theme.surface.accent};
`;
